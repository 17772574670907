export const navbarItems = [
  {
    label: 'Home',
    to: '/',
  },
  {
    label: 'Portfolio',
    to: '/portfolio',
  },
  {
    label: 'About',
    to: '/about',
  },
  // {
  //   label: 'Contact',
  //   to: '/contact',
  // },
];
